import React, { Component, Fragment } from 'react';
import Menu from "./Menu";
import ImageHeader from '../Assets/Images/imgbghead.webp'
import '../Assets/Css/header.css'

class Header extends Component {
    render() {
        return (
            <>
                <Menu />
            </>

        )
    }
}

export default Header
